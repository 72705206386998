import { SVGAttributes } from 'react';

export default function ApplicationLogo({
  withBackground,
  ...props
}: SVGAttributes<SVGElement> & {
  withBackground?: boolean;
}) {
  if (withBackground) {
    return (
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 138 55" {...props}>
        <path
          fill="#FFF"
          d="M110.7,0H27.3C12.2,0,0,12.3,0,27.5C0,42.7,12.2,55,27.3,55h83.5c15.1,0,27.3-12.3,27.3-27.5
	C138,12.3,125.8,0,110.7,0z M94.6,10.1c0-2.4,1.8-4,4.2-4c2.4,0,4.2,1.6,4.2,4v0.7c0,2.4-2.1,4-4.2,4c-2.1,0-4.2-1.6-4.2-4V10.1z
	 M31.1,37.7c0,2-1.8,3.3-3.5,3.3c-1.7,0-3.5-1.3-3.5-3.3v-19c0-2,1.5-3.3,3.5-3.3c2,0,3.5,1.3,3.5,3.3V37.7z M71.2,37.7
	c0,2-1.3,3.3-3.6,3.3c-2.3,0-3.5-1.3-3.5-3.3V26.4c0-2.9-1.3-4.6-3.7-4.6c-2.4,0-3.7,1.6-3.7,4.6v11.3c0,2-1.3,3.3-3.6,3.3
	c-2.2,0-3.5-1.3-3.5-3.3V26.4c0-2.9-1.3-4.6-3.7-4.6c-2.4,0-3.7,1.6-3.7,4.6v11.3c0,2-1.2,3.3-3.5,3.3c-2.3,0-3.6-1.3-3.6-3.3V26.4
	c0-8.7,4.2-11.2,10.8-11.2c3,0,5.5,1,7.3,3.5c1.8-2.6,4.3-3.5,7.3-3.5c6.9,0,10.8,3.3,10.8,11.2V37.7z M82.1,37.7
	c0,2-1.8,3.3-3.5,3.3c-1.7,0-3.5-1.3-3.5-3.3v-19c0-2,1.5-3.3,3.5-3.3c2,0,3.5,1.3,3.5,3.3V37.7z M109.6,33.9h0.9
	c2.4,0,3.3,1.8,3.3,3.5c0,1.7-0.9,3.5-3.3,3.5h-0.9c-7.5,0-9.6-2.7-10.6-5.8C98,38,95.9,41,88.5,41h-0.2c-2.4,0-3.3-1.8-3.3-3.5
	c0-1.7,0.9-3.5,3.3-3.5h0.2c6,0,7.2-2.9,7.2-5.9c0-3-1.3-5.9-7.2-5.9h-0.2c-2.4,0-3.3-1.8-3.3-3.5c0-1.7,0.9-3.5,3.3-3.5h0.2
	c7.4,0,9.5,3,10.6,5.8c1-3,3.1-5.8,10.6-5.8h0.9c2.4,0,3.3,1.8,3.3,3.5c0,1.7-0.9,3.5-3.3,3.5h-0.9c-6,0-7.2,2.9-7.2,5.9
	C102.4,31.1,103.6,33.9,109.6,33.9z"
        />
        <g>
          <path fill="#00A69C" d="M94.6,10.1c0-2.4,1.8-4,4.2-4c2.4,0,4.2,1.6,4.2,4v0.7c0,2.4-2.1,4-4.2,4c-2.1,0-4.2-1.6-4.2-4V10.1z" />
          <path fill="#00A69C" d="M31.1,37.7c0,2-1.8,3.3-3.5,3.3c-1.7,0-3.5-1.3-3.5-3.3v-19c0-2,1.5-3.3,3.5-3.3c2,0,3.5,1.3,3.5,3.3V37.7z" />
          <path
            fill="#00A69C"
            d="M71.2,37.7c0,2-1.3,3.3-3.6,3.3c-2.3,0-3.5-1.3-3.5-3.3V26.4c0-2.9-1.3-4.6-3.7-4.6c-2.4,0-3.7,1.6-3.7,4.6
		v11.3c0,2-1.3,3.3-3.6,3.3c-2.2,0-3.5-1.3-3.5-3.3V26.4c0-2.9-1.3-4.6-3.7-4.6c-2.4,0-3.7,1.6-3.7,4.6v11.3c0,2-1.2,3.3-3.5,3.3
		c-2.3,0-3.6-1.3-3.6-3.3V26.4c0-8.7,4.2-11.2,10.8-11.2c3,0,5.5,1,7.3,3.5c1.8-2.6,4.3-3.5,7.3-3.5c6.9,0,10.8,3.3,10.8,11.2V37.7z
		"
          />
          <path fill="#00A69C" d="M82.1,37.7c0,2-1.8,3.3-3.5,3.3c-1.7,0-3.5-1.3-3.5-3.3v-19c0-2,1.5-3.3,3.5-3.3c2,0,3.5,1.3,3.5,3.3V37.7z" />
          <path
            fill="#00A69C"
            d="M109.6,33.9h0.9c2.4,0,3.3,1.8,3.3,3.5c0,1.7-0.9,3.5-3.3,3.5h-0.9c-7.5,0-9.6-2.7-10.6-5.8
		C98,38,95.9,41,88.5,41h-0.2c-2.4,0-3.3-1.8-3.3-3.5c0-1.7,0.9-3.5,3.3-3.5h0.2c6,0,7.2-2.9,7.2-5.9c0-3-1.3-5.9-7.2-5.9h-0.2
		c-2.4,0-3.3-1.8-3.3-3.5c0-1.7,0.9-3.5,3.3-3.5h0.2c7.4,0,9.5,3,10.6,5.8c1-3,3.1-5.8,10.6-5.8h0.9c2.4,0,3.3,1.8,3.3,3.5
		c0,1.7-0.9,3.5-3.3,3.5h-0.9c-6,0-7.2,2.9-7.2,5.9C102.4,31.1,103.6,33.9,109.6,33.9z"
          />
        </g>
      </svg>
    );
  }

  return (
    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 163 63" {...props}>
      <path
        fill="#00a69c"
        d="m127.9 7.2c0-4.3 3.3-7.2 7.6-7.2 4.3 0 7.6 2.9 7.6 7.2v1.3c-0.2 4.2-3.7 7.5-7.9 7.3-4-0.1-7.2-3.3-7.3-7.3zm-114.7 49.9c0 3.6-3.2 6-6.3 6-3.1 0-6.3-2.3-6.3-6v-34.3c0-3.6 2.7-6 6.3-6 3.6 0 6.3 2.4 6.3 6zm72.4 0c0 3.6-2.3 6-6.5 6-4.2 0-6.3-2.3-6.3-6v-20.4c0-5.2-2.3-8.3-6.7-8.3-4.4 0-6.7 2.9-6.7 8.3v20.4c0 3.6-2.3 6-6.5 6-4 0-6.3-2.3-6.3-6v-20.4c0-5.2-2.3-8.3-6.7-8.3-4.4 0-6.7 2.9-6.7 8.3v20.4c0 3.6-2.2 6-6.3 6-4.1 0-6.5-2.3-6.5-6v-20.4c0-15.7 7.6-20.2 19.5-20.2 5.4 0 9.9 1.8 13.2 6.3 3.2-4.7 7.8-6.3 13.2-6.3 12.5 0 19.5 6 19.5 20.2v20.4zm19.7 0c0 3.6-3.2 6-6.3 6-3.1 0-6.3-2.3-6.3-6v-34.3c0-3.6 2.7-6 6.3-6 3.6 0 6.3 2.4 6.3 6zm49.6-6.9h1.6c4.3 0 6 3.2 6 6.3 0 3.1-1.6 6.3-6 6.3h-1.6c-13.6 0-17.3-4.9-19.1-10.5-1.8 5.2-5.6 10.6-19 10.6h-0.4c-4.3 0-6-3.2-6-6.3 0-3.1 1.6-6.3 6-6.3h0.4c10.8 0 13-5.2 13-10.7 0-5.5-2.4-10.6-13-10.6h-0.4c-4.3 0-6-3.2-6-6.3 0-3.1 1.6-6.3 6-6.3h0.4c13.4 0 17.1 5.4 19.1 10.5 1.8-5.4 5.6-10.5 19.1-10.5h1.6c4.3 0 6 3.2 6 6.3 0 3.1-1.6 6.3-6 6.3h-1.6c-10.8 0-13 5.2-13 10.7-0.1 5.4 2.1 10.5 12.9 10.5z"
      />
    </svg>
  );
}
