import { usePage } from '@inertiajs/react';
import { useEffect } from 'react';
import { toast } from 'sonner';

export const useFlashNotifications = () => {
  const { flash } = usePage().props;
  useEffect(() => {
    if (Array.isArray(flash)) {
      flash.forEach(({ type, key, message }) => {
        if (typeof toast[type] === 'function') {
          toast[type](message, { id: key });
        } else {
          toast(message, { id: key });
        }
      });
    }
  }, [JSON.stringify(flash)]);
};
